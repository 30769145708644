import { Action, combineReducers, Reducer } from 'redux';
// @ts-expect-error - Automatic, Please fix when editing this file
import { CookieStorage } from 'redux-persist-cookie-storage';
import { getOr, includes, merge, pick } from 'lodash/fp';
import { HYDRATE } from 'next-redux-wrapper';
import { persistReducer } from 'redux-persist';
import { routerReducer } from 'connected-next-router';
// @ts-expect-error - Automatic, Please fix when editing this file
import autoMergeLevel1 from 'redux-persist/lib/stateReconciler/autoMergeLevel1';
import Cookies from 'cookies-js';
// @ts-expect-error - Automatic, Please fix when editing this file
import livechat from '@ahmdigital/livechat/lib/reducers';
// @ts-expect-error - Automatic, Please fix when editing this file
import localStorage from 'redux-persist/lib/storage/';
import maintenance from '@ahmdigital/logic/lib/maintenance/reducers';
import sessionStorage from 'redux-persist/lib/storage/session';

import abtest from './abtest';
import accordion from './accordion';
import buildYourCover from './build-your-cover';
import cart from './cart';
import checkout from './checkout';
import choosable from './choosable';
import cms from '../cms/reducers';
import constants from '../ahm-constants';
import customer from './customer';
import drawer from './drawer';
import featureToggle from './feature-toggle';
import footer from './footer';
import form from './form';
import getIsServer from '../utils/get-is-server';
import historyReducer from './history';
import location from './location';
import membership from './membership';
import modal from './modal';
import offers from './offers';
import price from './price';
import product from './product';
import productComparer from './product-comparer';
import productListingOverlay from './product-listing-overlay';
import referral from './referral';
import referrer from './referrer';
import requestACall from './request-a-call';
import saveAndRetrieveQuote from './save-and-retrieve-quote';
import sessionTransform from './transforms/session';
import toast from './toast';

const REDUCERS_WITH_REHYDRATION_LOGIC = ['cart', 'customer'];
const SECONDS_IN_ONE_DAY = 86400;

// @ts-expect-error - Automatic, Please fix when editing this file
const getCookiePersistConfig = (key, opts = {}) => ({
  key,
  keyPrefix: constants.REDUX_PERSIST_KEY_PREFIX,
  stateReconciler: includes(key, REDUCERS_WITH_REHYDRATION_LOGIC) ? false : autoMergeLevel1,
  storage: new CookieStorage(Cookies, {
    expiration: {
      default: getOr(365 * SECONDS_IN_ONE_DAY, 'expiration', opts), // Cookies expire after one year
    },
  }),
});

// @ts-expect-error - Automatic, Please fix when editing this file
const getSessionPersistConfig = (key, persistStorageType = sessionStorage) => ({
  key,
  keyPrefix: constants.REDUX_PERSIST_KEY_PREFIX,
  storage: persistStorageType,
  transforms: [sessionTransform],
});

// @ts-expect-error - Automatic, Please fix when editing this file
const getLocalPersistConfig = (key) => getSessionPersistConfig(key, localStorage);

const getPersistReducer = (
  name: string,
  // @ts-expect-error - Automatic, Please fix when editing this file
  reducer,
  persistStorageType = 'session',
  persistStorageOpts = {},
): Reducer<any, Action<unknown>> => {
  const isServer = getIsServer();
  if (!isServer) {
    let persistConfig;
    switch (persistStorageType) {
      case 'session':
        persistConfig = getSessionPersistConfig(name);
        break;
      case 'local':
        persistConfig = getLocalPersistConfig(name);
        break;
      default:
        persistConfig = getCookiePersistConfig(name, persistStorageOpts);
    }
    return persistReducer(persistConfig, reducer);
  }
  return reducer;
};

const buildCombinedReducers = () =>
  combineReducers({
    abtest: getPersistReducer('abtest', abtest, 'cookie'),
    accordion,
    buildYourCover: getPersistReducer('buildYourCover', buildYourCover, 'cookie'),
    cart: getPersistReducer('cart', cart, 'cookie'),
    checkout: getPersistReducer('checkout', checkout),
    choosable: getPersistReducer('choosable', choosable),
    cms: getPersistReducer('cms', cms, 'local'),
    customer: getPersistReducer('customer', customer, 'cookie'),
    drawer,
    featureToggle: getPersistReducer('featureToggle', featureToggle, 'cookie'),
    footer,
    form: getPersistReducer('form', form),
    history: historyReducer,
    livechat: livechat as Reducer<any, Action<unknown>>,
    location,
    maintenance,
    membership: getPersistReducer('membership', membership),
    modal,
    offers,
    price,
    product,
    productComparer,
    productListingOverlay,
    referral: getPersistReducer('referral', referral, 'cookie', { expiration: 3 * SECONDS_IN_ONE_DAY }),
    referrer: getPersistReducer('referrer', referrer, 'cookie', { expiration: 30 * SECONDS_IN_ONE_DAY }),
    requestACall: getPersistReducer('requestACall', requestACall),
    router: routerReducer,
    saveAndRetrieveQuote: getPersistReducer('saveAndRetrieveQuote', saveAndRetrieveQuote),
    toast,
  });

export type RootState = ReturnType<ReturnType<typeof buildCombinedReducers>>;

/**
 * This action happens on route change.
 *
 * @param {object} existingClientState - The state already in the browser, which may include user-set state (such as customer.scale)
 * or data previously received from SSR/SSG, like products.
 * @param {object} newServerState - Is data passed in from the SSR/SSG stage, such as new price data retrieved from API.
 *
 * This function needs to reconcile these two together, so that we get the new data without losing the old.
 * This logic works as long as server data stores are separate from user state stores, so that:
 * - we can always overwrite a server data store with the new state from SSR/SSG, as there's no user-set state in there.
 * - we can always use the existing client state for a user-state store, as there's no API data in there we need to preserve.
 */
// @ts-expect-error - Automatic, Please fix when editing this file
const reconcileHydratedState = (existingClientState, newServerState) => {
  const newServerStateApiDataOnly = pick(['price', 'product'], newServerState);

  // this field is the only one that's set client-side, in a store that else stores only server data
  // we should fix this to only be set in SSR so we don't need this edge case
  // without this line, useFutureEffectiveDate will be reset to false every time prices load on page transition.
  newServerStateApiDataOnly.price.useFutureEffectiveDate = existingClientState.price.useFutureEffectiveDate;

  return merge(existingClientState, newServerStateApiDataOnly);
};

const createRootReducer = () => {
  const combinedReducers = buildCombinedReducers();

  // @ts-expect-error - Automatic, Please fix when editing this file
  return (state, action) => {
    if (action.type === HYDRATE) {
      return reconcileHydratedState(state, action.payload);
    }

    return combinedReducers(state, action);
  };
};

export default createRootReducer;

export { getCookiePersistConfig, getSessionPersistConfig, getPersistReducer, reconcileHydratedState };
