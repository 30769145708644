import { List, P, Strong } from '@ahmdigital/ui';
import React from 'react';

import { ambulanceDisclaimerTasQld } from './shared';

export default {
  intro: {
    accidentOverrideDescription: (
      <>
        <P>
          Get solid silver that shines - without stuff you don’t need (all those pregnancy things) with these additional
          hospital services:
        </P>
        <List variant={['inside', 'withBottomMargin']}>
          <li>
            <Strong>Joint replacements</Strong>
          </li>
          <li>
            <Strong>Cataracts</Strong>
          </li>
          <li>
            <Strong>Dialysis for chronic kidney failure</Strong>
          </li>
          <li>
            <Strong>Insulin pumps</Strong>
          </li>
          <li>
            <Strong>Pain management with device</Strong>
          </li>
          <li>
            <Strong>Sleep studies</Strong>
          </li>
          <li>
            <Strong>No excess for dependants</Strong>
          </li>
          <li>
            <Strong>unlimited medically necessary ambulance^</Strong>
          </li>
        </List>
      </>
    ),
    description: (
      <>
        <P>
          Get solid silver that shines - without stuff you don’t need (all those pregnancy things) with these additional
          hospital services:
        </P>
        <List variant={['inside', 'withBottomMargin']}>
          <li>
            <Strong>Joint replacements</Strong>
          </li>
          <li>
            <Strong>Cataracts</Strong>
          </li>
          <li>
            <Strong>Dialysis for chronic kidney failure</Strong>
          </li>
          <li>
            <Strong>Insulin pumps</Strong>
          </li>
          <li>
            <Strong>Pain management with device</Strong>
          </li>
          <li>
            <Strong>Sleep studies</Strong>
          </li>
        </List>
        <P>
          You also get <Strong>unlimited medically necessary ambulance^</Strong>, <Strong>accident override</Strong> and{' '}
          <Strong>no excess for dependants</Strong>.
        </P>
      </>
    ),
    disclaimer: ambulanceDisclaimerTasQld,
  },
};
