import { List, P, Strong } from '@ahmdigital/ui';
import React from 'react';

import { ambulanceDisclaimerTasQld } from './shared';

export default {
  intro: {
    accidentOverrideDescription: (
      <>
        <P>
          Get basic hospital cover for those sporting moments where you just went a little too hard (we believe you),
          with these added services to get you back in the game:
        </P>
        <List variant={['inside', 'withBottomMargin']}>
          <li>
            <Strong>Dental surgery</Strong>
          </li>
          <li>
            <Strong>Hernia and appendix</Strong>
          </li>
          <li>
            <Strong>Joint reconstructions</Strong>
          </li>
          <li>
            <Strong>Tonsils, adenoids and grommets</Strong>
          </li>
          <li>
            <Strong>Unlimited medically necessary ambulance^</Strong>
          </li>
        </List>
      </>
    ),
    description: (
      <>
        <P>
          Get basic hospital cover for those sporting moments where you just went a little too hard (we believe you),
          with these added services to get you back in the game:
        </P>
        <List variant={['inside', 'withBottomMargin']}>
          <li>
            <Strong>Dental surgery</Strong>
          </li>
          <li>
            <Strong>Hernia and appendix</Strong>
          </li>
          <li>
            <Strong>Joint reconstructions</Strong>
          </li>
          <li>
            <Strong>Tonsils, adenoids and grommets</Strong>
          </li>
        </List>
        <P>
          You also get <Strong>unlimited medically necessary ambulance^</Strong> and <Strong>accident override</Strong>{' '}
          just in case.
        </P>
      </>
    ),
    disclaimer: ambulanceDisclaimerTasQld,
  },
};
