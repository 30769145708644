import { List, P, Strong } from '@ahmdigital/ui';
import React from 'react';

import { ambulanceDisclaimerTasQld } from './shared';
import constants from '../../../ahm-constants';

export default {
  intro: {
    accidentOverrideDescription: (
      <>
        <P>Bronze category cover, including these bonus services:</P>
        <List variant={['inside', 'withBottomMargin']}>
          <li>
            <Strong>Back, neck and spine</Strong>
          </li>
          <li>
            <Strong>Dental surgery</Strong>
          </li>
          <li>
            <Strong>Lung and chest</Strong>
          </li>
          <li>
            <Strong>Pre & postnatal services</Strong>
          </li>
          <li>
            <Strong>No excess for dependants</Strong>
          </li>
          <li>
            <Strong>Unlimited medically necessary ambulance^</Strong>
          </li>
        </List>
        <P>
          With a range of <Strong>popular extras like dental, physio, chiro</Strong> and{' '}
          <Strong>health improvement benefits</Strong> for an added layer of family protection.
        </P>
      </>
    ),
    description: (
      <>
        <P>Bronze category cover, including these bonus services:</P>
        <List variant={['inside', 'withBottomMargin']}>
          <li>
            <Strong>Back, neck and spine</Strong>
          </li>
          <li>
            <Strong>Dental surgery</Strong>
          </li>
          <li>
            <Strong>Lung and chest</Strong>
          </li>
        </List>
        <P>
          Also get <Strong>accident override</Strong> and <Strong>unlimited medically necessary ambulance^</Strong>.
          You’ll also pay <Strong>no excess for dependants</Strong> and get <Strong>pre & postnatal services</Strong> if
          you’re planning to have kids.
        </P>
        <P>
          With a range of <Strong>popular extras like dental, physio, chiro</Strong> and{' '}
          <Strong>health improvement benefits</Strong> for an added layer of family protection.
        </P>
      </>
    ),
    disclaimer: ambulanceDisclaimerTasQld,
  },
  upgrade: {
    cta: 'Check out classic flexi silver plus',
    id: constants.PRODUCT.CLASSIC_FLEXI_SILVER_PLUS_EXCESS_500,
    title: 'Looking for a step up in hospital cover?',
  },
};
