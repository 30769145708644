import { List, P, Strong } from '@ahmdigital/ui';
import React from 'react';

import { ambulanceDisclaimerTasQld } from './shared';

export default {
  intro: {
    accidentOverrideDescription: (
      <>
        <P>Your bronze category cover starts here, including these important services:</P>
        <List variant={['inside', 'withBottomMargin']}>
          <li>
            <Strong>Bone, joint and muscle</Strong>
          </li>
          <li>
            <Strong>Chemotherapy, radiotherapy and immunotherapy for cancer</Strong>
          </li>
          <li>
            <Strong>Gastrointestinal endoscopy</Strong>
          </li>
          <li>
            <Strong>Joint reconstructions</Strong>
          </li>
          <li>
            <Strong>Unlimited medically necessary ambulance^</Strong>
          </li>
        </List>
      </>
    ),
    description: (
      <>
        <P>Your bronze category cover starts here, including these important services:</P>
        <List variant={['inside', 'withBottomMargin']}>
          <li>
            <Strong>Bone, joint and muscle</Strong>
          </li>
          <li>
            <Strong>Chemotherapy, radiotherapy and immunotherapy for cancer</Strong>
          </li>
          <li>
            <Strong>Gastrointestinal endoscopy</Strong>
          </li>
          <li>
            <Strong>Joint reconstructions</Strong>
          </li>
        </List>
        <P>
          Also comes with <Strong>unlimited medically necessary ambulance^</Strong> and{' '}
          <Strong>accident override</Strong> just in case.
        </P>
      </>
    ),
    disclaimer: ambulanceDisclaimerTasQld,
  },
};
