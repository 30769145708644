import { List, P, Strong } from '@ahmdigital/ui';
import React from 'react';

import { ambulanceDisclaimerTasQld } from './shared';

export default {
  intro: {
    accidentOverrideDescription: (
      <>
        <P>Silver Hospital cover, with a side order of added benefits:</P>
        <List variant={['inside', 'withBottomMargin']}>
          <li>
            <Strong>Sleep studies</Strong>
          </li>
          <li>
            <Strong>Palliative care</Strong>
          </li>
          <li>
            <Strong>Rehab</Strong>
          </li>
          <li>
            <Strong>No excess for dependants</Strong>
          </li>
          <li>
            <Strong>Unlimited medically necessary ambulance^</Strong>
          </li>
        </List>
      </>
    ),
    description: (
      <>
        <P>Silver Hospital cover, with a side order of added benefits:</P>
        <List variant={['inside', 'withBottomMargin']}>
          <li>
            <Strong>Sleep studies</Strong>
          </li>
        </List>
        <P>
          Palliative care and rehab are also included. You also get{' '}
          <Strong>unlimited medically necessary ambulance^</Strong>, accident override and no excess for dependants.
        </P>
      </>
    ),
    disclaimer: ambulanceDisclaimerTasQld,
  },
};
