export const CONTENT_TYPE = {
  AWARD: 'Award',
  COMMON_QUESTIONS: 'CommonQuestions',
  CONTENT_TILES: 'ContentTiles',
  HOW_TO: 'HowTo',
  RELATED_TOPICS_URL: 'RelatedTopicsUrl',
  SELLING_POINT: 'SellingPointGroup',
  TOPIC_GROUP: 'TopicGroup',
  VALUE_PROPOSITIONS: 'ValuePropositionsObject',
};

const CONTENT_TILE_FIELDS = `
  name
  slogan: sloganRaw
  summary: summaryRaw
  urlObject {
    name
    url
  }
  type: _type
`;

export const MODE = {
  DRAFT: 'draft',
  PUBLISHED: 'published',
};

export const DATASET = {
  PRODUCTION: 'production',
  STAGING: 'staging',
};

export const NOW = 'NOW';

export const MIDDAY = '12:00';

export const TARGET_SEGMENT = {
  FRIEND_LOOKING_FOR_PRIVATE_HEALTH_INSURANCE: 'friend-looking-for-private-health-insurance',
  LOOKING_FOR_PRIVATE_HEALTH_INSURANCE: 'looking-for-private-health-insurance',
  PARTNER_LOOKING_FOR_PRIVATE_HEALTH_INSURANCE: 'partner-looking-for-private-health-insurance',
};

const IMAGE_FIELDS = `
  asset {
    _id
    url
  }
  hotspot {
    x
    y
    height
    width
  }
  crop {
    top
    bottom
    left
    right
  }
`;

const IMAGE = `image {
  ${IMAGE_FIELDS}
}`;

const IMAGE_OBJECT = `
  imageObject {
    caption
    ${IMAGE}
  }
`;

const OFFER_TYPE_CONDITIONS = `
  hasConditionsGroup {
    containsConditions {
      name
      importance
      identifier {
        current
      }
      description:descriptionRaw
    }
    name
    identifier {
      current
    }
    _id
  },
  hasSpecificConditions {
    name
    importance
    identifier {
      current
    }
    description:descriptionRaw
  }
`;

const OFFER_TYPES = `
  offerTypes {
    _id
    fulfilledAfterDays
    givesAccessToOneOfRewards {
      identifier {
        current
      }
      name
    }
    identifier {
      current
    }
    ${IMAGE_OBJECT}
    ${OFFER_TYPE_CONDITIONS}
    offeredByOrganization {
      identifier {
        current
      }
    }
    name
    slogan: sloganRaw
  }
`;

const RELEASE = `
  release {
    startTime
    endTime
  }
`;

const URL_OBJECT_PROPS = `{
    name
    url
  }`;

export const GRAPHQL = {
  CONTENT_TILE_FIELDS,
  ID: '_id',
  IMAGE,
  IMAGE_FIELDS,
  IMAGE_OBJECT,
  OFFER_TYPES,
  OFFER_TYPE_CONDITIONS,
  RELEASE,
  SLUG: `identifier {
    current
  }`,
  TYPE: '_type',
  URL_OBJECT: `urlObject ${URL_OBJECT_PROPS}`,
  URL_OBJECTS: `urlObjects ${URL_OBJECT_PROPS}`,
};

const ANNOUNCEMENTS = `
  ... on Alert {
    ${GRAPHQL.ID}
    name
    title
    descriptionRaw
    level
    ${GRAPHQL.TYPE}
  }
`;

export const FRAGMENTS = {
  ANNOUNCEMENTS,
};
