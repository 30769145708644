import { concat, flatMap, map, mapValues, union, without } from 'lodash/fp';
import {
  CUSTOMER_NEEDS,
  HEALTH_FUND,
  NEEDS_PRODUCT_TYPE_SLUG,
  PARENT_PRODUCT,
  PAYMENT_FREQUENCY,
  PRODUCT,
  PRODUCT_TYPE,
  SCALE,
  STATE,
  UI,
} from '@ahmdigital/constants';

import { URLS } from '../models/url/constants';
import config from '../config';
import uncapFpIterator from '../../utils/uncap-fp-iterator';
import unfixFpIterator from '../../utils/unfix-fp-iterator';

const sanityConfig = config.get('sanity');

export const REDUX_PERSIST_KEY_PREFIX = 'persist_';

export const BUY_STEP = {
  ABOUT: 'about',
  AGR: 'agr',
  COVER: 'cover',
  EMAIL: 'email',
  PAYMENT: 'payment',
  SUCCESS: 'success',
};

export const PAYMENT_TYPE = {
  BANK: 'bank',
  CREDIT: 'credit',
};

export const SCALE_ADULT_DEPENDANT_VARIANT = {
  [SCALE.FAMILY]: SCALE.FAMILY_PLUS_21,
  [SCALE.SINGLE_PARENT]: SCALE.SINGLE_PARENT_PLUS_21,
};

// Dropdown options
export const HEALTH_FUND_OPTIONS = [
  {
    text: 'Please select',
    value: '',
  },
  {
    text: 'Medibank Private',
    value: HEALTH_FUND.MEDIBANK,
  },
  {
    text: 'BUPA',
    value: HEALTH_FUND.BUPA,
  },
  {
    text: 'HCF',
    value: HEALTH_FUND.HCF,
  },
  {
    text: 'NIB',
    value: HEALTH_FUND.NIB,
  },
  {
    text: 'Australian Unity',
    value: HEALTH_FUND.AUSTRALIAN_UNITY,
  },
  {
    text: 'Latrobe',
    value: HEALTH_FUND.LATROBE,
  },
  {
    text: 'HBF',
    value: HEALTH_FUND.HBF,
  },
  {
    text: 'GMHBA',
    value: HEALTH_FUND.GMHBA,
  },
  {
    text: 'HBA',
    value: HEALTH_FUND.HBA,
  },
  {
    text: 'Frank',
    value: HEALTH_FUND.FRANK,
  },
  {
    text: 'GMF',
    value: HEALTH_FUND.GMF,
  },
  {
    text: 'Health.com.au',
    value: HEALTH_FUND.HEALTH_COM_AU,
  },
  {
    text: 'AAMI',
    value: HEALTH_FUND.AAMI,
  },
  {
    text: 'ACA Health',
    value: HEALTH_FUND.ACA,
  },
  {
    text: 'APIA',
    value: HEALTH_FUND.APIA,
  },
  {
    text: 'Budget Direct',
    value: HEALTH_FUND.BUDGET_DIRECT,
  },
  {
    text: 'CBHS',
    value: HEALTH_FUND.CBHS,
  },
  {
    text: 'CDH',
    value: HEALTH_FUND.CDH,
  },
  {
    text: 'Central West Health',
    value: HEALTH_FUND.CENTRAL_WEST,
  },
  {
    text: 'Country Health',
    value: HEALTH_FUND.COUNTRY_HEALTH,
  },
  {
    text: 'Credicare',
    value: HEALTH_FUND.CREDICARE,
  },
  {
    text: 'CUA',
    value: HEALTH_FUND.CUA,
  },
  {
    text: 'CY Health',
    value: HEALTH_FUND.CY_HEALTH,
  },
  {
    text: 'DefenceHealth',
    value: HEALTH_FUND.DEFENCE,
  },
  {
    text: 'Doctors Health Fund',
    value: HEALTH_FUND.DOCTORS,
  },
  {
    text: 'Druids',
    value: HEALTH_FUND.DRUIDS,
  },
  {
    text: 'Federation Health',
    value: HEALTH_FUND.FEDERATION_HEALTH,
  },
  {
    text: 'FIT',
    value: HEALTH_FUND.FIT,
  },
  {
    text: 'Goldfields',
    value: HEALTH_FUND.GOLDFIELDS,
  },
  {
    text: 'Grand United',
    value: HEALTH_FUND.GRAND_UNITED,
  },
  {
    text: 'GU Health',
    value: HEALTH_FUND.GU,
  },
  {
    text: 'Health Care Insurance',
    value: HEALTH_FUND.HEALTH_CARE_INSURANCE,
  },
  {
    text: 'Health Partners',
    value: HEALTH_FUND.HEALTH_PARTNERS,
  },
  {
    text: 'Healthguard',
    value: HEALTH_FUND.HEALTHGUARD,
  },
  {
    text: 'HIF',
    value: HEALTH_FUND.HIF,
  },
  {
    text: 'Illawarra Health Fund',
    value: HEALTH_FUND.ILLAWARRA_HEALTH_FUND,
  },
  {
    text: 'IMAN',
    value: HEALTH_FUND.IMAN,
  },
  {
    text: 'IOOF',
    value: HEALTH_FUND.IOOF,
  },
  {
    text: 'IOR',
    value: HEALTH_FUND.IOR,
  },
  {
    text: 'Manchester Unity',
    value: HEALTH_FUND.MANCHESTER_UNITY,
  },
  {
    text: 'MBF',
    value: HEALTH_FUND.MBF,
  },
  {
    text: 'MDHF',
    value: HEALTH_FUND.MDHF,
  },
  {
    text: 'Mutual Health',
    value: HEALTH_FUND.MUTUAL_HEALTH,
  },
  {
    text: 'myOwn',
    value: HEALTH_FUND.MY_OWN,
  },
  {
    text: 'Navy Health',
    value: HEALTH_FUND.NAVY,
  },
  {
    text: 'NRMA Health',
    value: HEALTH_FUND.NRMA_HEALTH,
  },
  {
    text: 'NSW Teachers Federation',
    value: HEALTH_FUND.NSW_TEACHERS_FEDERATION,
  },
  {
    text: 'Nurses & Midwives Health',
    value: HEALTH_FUND.NURSES_AND_MIDWIVES_HEALTH,
  },
  {
    text: 'Onemedifund',
    value: HEALTH_FUND.ONEMEDIFUND,
  },
  {
    text: 'Peoplecare',
    value: HEALTH_FUND.PEOPLECARE,
  },
  {
    text: 'Phoenix Health Fund',
    value: HEALTH_FUND.PHOENIX,
  },
  {
    text: 'Police Health Fund',
    value: HEALTH_FUND.POLICE,
  },
  {
    text: 'Qantas Assure',
    value: HEALTH_FUND.QANTAS_ASSURE,
  },
  {
    text: 'Queensland Country Health',
    value: HEALTH_FUND.QUEENSLAND_COUNTRY,
  },
  {
    text: 'Queensland Teachers Union',
    value: HEALTH_FUND.QUEENSLAND_TEACHERS_UNION,
  },
  {
    text: 'RACT',
    value: HEALTH_FUND.RACT,
  },
  {
    text: 'RBHS',
    value: HEALTH_FUND.RBHS,
  },
  {
    text: 'RT Health Fund',
    value: HEALTH_FUND.RT,
  },
  {
    text: 'SGIC (SA)',
    value: HEALTH_FUND.SGIC_SA,
  },
  {
    text: 'SGIC (WA)',
    value: HEALTH_FUND.SGIC_WA,
  },
  {
    text: 'St. Lukes Health',
    value: HEALTH_FUND.ST_LUKES,
  },
  {
    text: 'Suncorp',
    value: HEALTH_FUND.SUNCORP,
  },
  {
    text: 'Transport Health',
    value: HEALTH_FUND.TRANSPORT,
  },
  {
    text: 'Unihealth',
    value: HEALTH_FUND.TEACHERS_HEALTH,
  },
  {
    text: 'Westfund Health',
    value: HEALTH_FUND.WESTFUND,
  },
  {
    text: 'Other',
    value: HEALTH_FUND.OTHER,
  },
];

export const PAYMENT_TYPE_OPTIONS = [
  {
    text: 'Direct debit',
    value: PAYMENT_TYPE.BANK,
  },
  {
    text: 'Credit card',
    value: PAYMENT_TYPE.CREDIT,
  },
];

export const TYPE_LABEL = {
  EXTRAS: 'Extras',
  HOSPITAL: 'Hospital',
  PACKAGE: 'Hospital and Extras',
};

export const SCALE_LABEL = {
  [SCALE.SINGLE]: 'Single',
  [SCALE.COUPLE]: 'Couple',
  [SCALE.FAMILY]: 'Family',
  [SCALE.SINGLE_PARENT]: 'Single Parent',
};

export const STATE_LABEL = {
  [STATE.ACT]: 'ACT',
  [STATE.NSW]: 'NSW',
  [STATE.QLD]: 'QLD',
  [STATE.SA]: 'SA',
  [STATE.TAS]: 'TAS',
  [STATE.VIC]: 'VIC',
  [STATE.WA]: 'WA',
  [STATE.NT]: 'NT',
};

export const PAYMENT_FREQUENCY_LABEL = {
  [PAYMENT_FREQUENCY.WEEKLY]: 'week',
  [PAYMENT_FREQUENCY.FORTNIGHTLY]: 'fortnight',
  [PAYMENT_FREQUENCY.MONTHLY]: 'month',
  [PAYMENT_FREQUENCY.YEARLY]: 'year',
};

export const LIVEPERSON = {
  LIVECHAT_URL_BLOCKLIST: [
    URLS.SALES.CAR_AND_HOME_INSURANCE_PRIVACY_POLICY,
    URLS.SALES.CAR_INSURANCE,
    URLS.SALES.CAR_INSURANCE_SUCCESS,
    URLS.SALES.CAR_INSURANCE_LOGIN,
    URLS.SALES.CAR_INSURANCE_QUOTE,
    URLS.SALES.CHOOSE_YOUR_COVER,
    URLS.SALES.HEALTH_INSURANCE,
    URLS.SALES.HOME_INSURANCE,
    URLS.SALES.HOME_INSURANCE_SUCCESS,
    URLS.SALES.HOME_INSURANCE_LOGIN,
    URLS.SALES.HOME_INSURANCE_QUOTE,
    URLS.SALES.LIFE_INSURANCE_CLAIM,
    URLS.SALES.LIFE_INSURANCE_CONTACT_US,
    URLS.SALES.LIFE_INSURANCE,
    URLS.SALES.TRAVEL_INSURANCE_CONTACT_US,
    URLS.SALES.TRAVEL_INSURANCE_MAKE_A_CLAIM,
    URLS.SALES.TRAVEL_INSURANCE,
  ],
  PROFILE_URL: 'https://link.liveperson.net/click?key=A4D6473AD78DA961',
};

export const OFFER_BANNER_WHITELIST_NEEDS_BASED = flatMap(
  (needType) =>
    map((productType) => `${URLS.SALES.PRODUCT_LISTING}/${needType}/${productType}`, NEEDS_PRODUCT_TYPE_SLUG),
  CUSTOMER_NEEDS,
);

export const OFFER_BANNER_WHITELIST = unfixFpIterator(union)(
  flatMap(
    (base) => map((needType) => `${base}/${needType}`, CUSTOMER_NEEDS),
    [URLS.SALES.PRODUCT_LISTING_HOSPITAL, URLS.SALES.NEEDS_JOURNEY_EXTRAS],
  ),
  [URLS.SALES.PRODUCT_LISTING_PACKAGES, URLS.SALES.PRODUCT_LISTING_HOSPITAL, URLS.SALES.PRODUCT_LISTING_EXTRAS],
  OFFER_BANNER_WHITELIST_NEEDS_BASED,
);

export const SWAG_SOURCE_CODE = 'U60373';

export const SWAG_12_WEEKS_FREE_ID = 'swag-12-Weeks-Free-WW';
export const SWAG_8_WEEKS_FREE_ID = 'swag-8-weeks-free-extras-only';

export const SWAG_OFFER_IDS = [SWAG_8_WEEKS_FREE_ID, SWAG_12_WEEKS_FREE_ID];

export const SWAG_OFFERS_BY_PRODUCT_TYPE = {
  [PRODUCT_TYPE.HOSPITAL]: SWAG_12_WEEKS_FREE_ID,
  [PRODUCT_TYPE.COMBINED]: SWAG_12_WEEKS_FREE_ID,
  [PRODUCT_TYPE.PACKAGE]: SWAG_12_WEEKS_FREE_ID,
  [PRODUCT_TYPE.EXTRAS]: SWAG_8_WEEKS_FREE_ID,
};

export const ANNOUNCEMENT_BANNER_ALLOWLIST = [URLS.SALES.CONTACT_US];

// @ts-expect-error - Automatic, Please fix when editing this file
export const AHM_SERVICE_NAME = uncapFpIterator(mapValues)((value, key) => key, UI.CONTACT.HOURS);

export const DRAWER_IDS = {
  CHANGE_DETAILS: 'change-details',
  COMPLEMENTARY_PRODUCT: 'complementary-product',
  GET_A_CALL_BACK: 'get-a-call-back',
  PICKER: 'picker',
  SAVE_AND_RETRIEVE_QUOTE: 'save-and-retrieve-quote',
};

export const TRAVEL_WEEKEND_CONTACT_HOURS = {
  SATURDAY: '9am – 4pm Saturday',
  SUNDAY: '10am – 3pm Sunday',
};

export const BEST_SELLERS_PRODUCT_LIST = {
  [NEEDS_PRODUCT_TYPE_SLUG.HOSPITAL]: {
    [SCALE.SINGLE]: PARENT_PRODUCT.STARTER_BRONZE,
    [SCALE.COUPLE]: PARENT_PRODUCT.STARTER_BASIC,
    [SCALE.FAMILY]: PARENT_PRODUCT.ESSENTIALS_SILVER_PLUS,
    [SCALE.SINGLE_PARENT]: PARENT_PRODUCT.CORE_BRONZE_PLUS,
  },
  [NEEDS_PRODUCT_TYPE_SLUG.EXTRAS]: {
    [SCALE.SINGLE]: PRODUCT.LIFESTYLE_EXTRAS,
    [SCALE.COUPLE]: PRODUCT.LIFESTYLE_EXTRAS,
    [SCALE.FAMILY]: PRODUCT.FAMILY_EXTRAS,
    [SCALE.SINGLE_PARENT]: PRODUCT.FAMILY_EXTRAS,
  },
  [NEEDS_PRODUCT_TYPE_SLUG.PACKAGE]: {
    [SCALE.SINGLE]: PARENT_PRODUCT.LITE_FLEXI_BRONZE_PLUS,
    [SCALE.COUPLE]: PARENT_PRODUCT.LITE_FLEXI_BRONZE_PLUS,
    [SCALE.FAMILY]: PARENT_PRODUCT.LITE_FLEXI_BRONZE_PLUS,
    [SCALE.SINGLE_PARENT]: PARENT_PRODUCT.LITE_FLEXI_BRONZE_PLUS,
  },
} as const;

export const REQUEST_A_CALL_VARIANT = {
  ENABLE: '1',
};

export const CHOOSABLE_PRODUCTS = [
  PRODUCT.CHOOSABLE_50_TEETH,
  PRODUCT.CHOOSABLE_60_TEETH_EYES,
  PRODUCT.CHOOSABLE_60_TEETH_MUSCLE_BONE,
  PRODUCT.CHOOSABLE_60_TEETH_WELLBEING,
  PRODUCT.CHOOSABLE_60_TEETH_EYES_MUSCLE_BONE,
  PRODUCT.CHOOSABLE_60_TEETH_EYES_WELLBEING,
  PRODUCT.CHOOSABLE_60_TEETH_WELLBEING_MUSCLE_BONE,
  PRODUCT.CHOOSABLE_60_TEETH_EYES_WELLBEING_MUSCLE_BONE,
];

export const CHOOSABLE_60_PRODUCTS = without([PRODUCT.CHOOSABLE_50_TEETH], CHOOSABLE_PRODUCTS);

export const TILE_VARIANTS = {
  FULL_WIDTH: 'fullWidth',
  HORIZONTAL: 'horizontal',
  VERTICAL: 'vertical',
  VERTICAL_CENTER: 'verticalCenter',
};

export const PAGE_TITLE_TEMPLATE = '%s - ahm health insurance';
export const PERKS_PAGE_TITLE_TEMPLATE = '%s - ahm perk';

export const SHORT_PAGE_TITLE_TEMPLATE = '%s - ahm';

export const EXCESS_TYPES = [0, 500, 750];

export const CHECKOUT_EMAIL_FIELDS = ['email', 'marketingOptIn', 'privacyAgreement'] as const;

const BUY_FORM_STEP_COVER_REQUIRED_FIELDS = [
  'dob',
  'firstName',
  'lastName',
  'phoneNumber',
  'residentialAddressLine1',
  'residentialAddressSuburb',
  'residentialAddressState',
  'residentialAddressPostCode',
  'title',
];
const BUY_FORM_STEP_AGR_REQUIRED_FIELDS = concat(BUY_FORM_STEP_COVER_REQUIRED_FIELDS, [
  'coverStartDate',
  'coverStartsToday',
  'declarationAgreement',
  'medicareExpiry',
  'medicareNumber',
  'paymentFrequency',
  'paymentStartDate',
  'switchingHealthFund',
]);
const BUY_FORM_STEP_PAYMENT_REQUIRED_FIELDS = concat(BUY_FORM_STEP_AGR_REQUIRED_FIELDS, ['rebateDeclaration']);

export const BUY_FORM_REQUIRED_FIELDS = {
  AGR: BUY_FORM_STEP_AGR_REQUIRED_FIELDS,
  COVER: BUY_FORM_STEP_COVER_REQUIRED_FIELDS,
  PAYMENT: BUY_FORM_STEP_PAYMENT_REQUIRED_FIELDS,
};

export const NEEDS_THREE_UP_PAGES = [
  CUSTOMER_NEEDS.BARE_BONES,
  CUSTOMER_NEEDS.FAMILY,
  CUSTOMER_NEEDS.EXTRAS_COVER,
  CUSTOMER_NEEDS.DECIDE_EXTRAS_COVER,
];

export const SELF_SERVE_EXPERIMENT = 'selfServe';
export const SELF_SERVE_VARIANT = {
  DECISION_TREE: '1',
  ORIGINAL: '0',
};

export enum PREMIUM_REVIEW_TOGGLE_STYLE {
  ALTERNATIVE_PAGE = 'alternativePage',
  CHOOSE_YOUR_COVER_PAGE = 'chooseYourCoverPage',
  COMPARER_PAGE = 'comparerPage',
  FLAT = 'flat',
  FLAT_WHITE = 'flatWhite',
  INLINE = 'inline',
  READ_ONLY = 'readonly',
  RIBBON = 'ribbon',
  SOFT_BUNDLE_PAGE = 'softBundlePage',
}

export enum USE_FUTURE_EFFECTIVE_DATE {
  BEFORE = 'before',
  AFTER = 'after',
}

export enum MEDIA_SIZE {
  XS = 'xs',
  MD = 'md',
  LG = 'lg',
  XL = 'xl',
}

export const STATIC_BUILD_REVALIDATE_TIME = 300; // five minutes

export const GROWTHBOOK_PRODUCT_DETAILS_CHANGE_THIS = 'product-details-change-this';

export const GROWTHBOOK_HOMEPAGE_OFFER_BANNER = 'homepage-offer-banner';

export const ACCORDION_INITIAL_ITEMS_COUNT = 6;

export const GROWTHBOOK_PRODUCT_COMPARER_UPLIFT_EXPERIMENT = 'product-comparer-uplift';
export const GROWTHBOOK_PRODUCT_COMPARER_UPLIFT_VARIANT = Object.freeze({
  B_VARIANT: '1',
  C_VARIANT: '2',
  ORIGINAL: '0',
});

export const GROWTHBOOK_PRODUCT_REVIEW_WIDGET_EXPERIMENT = 'product-review-widget-v2';
export const GROWTHBOOK_PRODUCT_REVIEW_WIDGET_VARIANT = {
  NEW: '1',
  ORIGINAL: '0',
};

export const GROWTHBOOK_CHOOSE_SOFT_BUNDLE_EXPERIMENT = 'choose-soft-bundle';
export const GROWTHBOOK_CHOOSE_SOFT_BUNDLE_VARIANT = Object.freeze({
  LOADING: '0',
  NEW: '1',
  ORIGINAL: '2',
});

export const GROWTHBOOK_ACCIDENT_OVERRIDE_EXPERIMENT = 'accident-override';
export const GROWTHBOOK_ACCIDENT_OVERRIDE_VARIANT = Object.freeze({
  NEW: '1',
  ORIGINAL: '0',
});

export const GROWTHBOOK_CASH_BACK_OFFER = 'cash-back-offer';

export const GROWTHBOOK_CAMPAIGN_LANDING_EXPERIMENT = 'campaign-landing';
export const GROWTHBOOK_CAMPAIGN_LANDING_VARIANT = {
  NEW: '1',
  ORIGINAL: '0',
};

const { mediaFileDomain, projectId, dataset } = sanityConfig;
const SANITY_MEDIA_URL = `${mediaFileDomain}/${projectId}/${dataset}`;

export const SANITY_MEDIA_FILES = {
  GLITCH_VIDEO: `${SANITY_MEDIA_URL}/a6d6d4a6e56b1ec2406bedd88e12de749fb1e4da.mp4`,
  PLANE_VIDEO: `${SANITY_MEDIA_URL}/96498e9af474ed2f6a50890bc1b2000479638ab2.mp4`,
};
