import { List, P, Small, Strong } from '@ahmdigital/ui';
import React from 'react';

import { ambulanceDisclaimerTasQld } from './shared';

export default {
  intro: {
    accidentOverrideDescription: (
      <>
        <P>All bronze inclusions plus the good stuff:</P>
        <List variant={['inside', 'withBottomMargin']}>
          <li>
            <Strong>Sleep studies</Strong>
          </li>
          <li>
            <Strong>Dental surgery</Strong>
          </li>
          <li>
            <Strong>Lung and chest</Strong>
          </li>
          <li>
            <Strong>No excess for dependants</Strong>
          </li>
          <li>
            <Strong>Benefits towards a TENS machine**</Strong>
          </li>
          <li>
            <Strong>Unlimited medically necessary ambulance^</Strong>
          </li>
        </List>
      </>
    ),
    description: (
      <>
        <P>All bronze inclusions plus the good stuff:</P>
        <List variant={['inside', 'withBottomMargin']}>
          <li>
            <Strong>Sleep studies</Strong>
          </li>
          <li>
            <Strong>Dental surgery</Strong>
          </li>
          <li>
            <Strong>Lung and chest</Strong>
          </li>
        </List>
        <P>
          You’ll also get{' '}
          <Strong>
            accident override, unlimited medically necessary ambulance^, benefits towards a TENS machine**
          </Strong>{' '}
          and <Strong>no excess for dependants</Strong>.
        </P>
      </>
    ),
    disclaimer: (
      <>
        {ambulanceDisclaimerTasQld}
        <br />
        <Small>**12 month Waiting Period applies.</Small>
      </>
    ),
  },
};
